import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { BlogLayout, Container } from 'layouts';
import { Header } from 'components';
import { BlogListItem } from 'components/blog';
import config from '../../config/site';

const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 1rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: auto;
  }
`;

const Tag = ({ pageContext }) => {
  const { posts, tag } = pageContext;

  return (
    <BlogLayout>
      <Helmet title={`${tag.name} | ${config.titleAlt}`} />
      <Header title={`All Posts on ${tag.name}`} />
      <Container>
        <Wrapper>
          {posts &&
            posts.map((post) => (
              <BlogListItem
                tagsPost
                key={post.id}
                cover={post.coverImage && post.coverImage.url}
                path={post.slug}
                title={post.title}
                subtitle={post.subtitle}
                readtime={post.readtime}
                excerpt={post.excerpt}
              />
            ))
          }
          {!posts && <p>No posts found for this tag.</p>}
        </Wrapper>
      </Container>
    </BlogLayout>
  );
};

export default Tag;

Tag.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.array,
    tag: PropTypes.object
  })
};
